import { Flex } from "@radix-ui/themes";
import styles from "./Sidebar.module.css";
import SidebarLogo from "./SidebarLogo";
import SidebarDropdownMenu from "./SidebarDropdownMenu";
import { User } from "../../types";

type SidebarProps = {
  children: React.ReactNode;
  user: User | null;
  logout: () => void;
};

export default function Sidebar({ user, logout, children }: SidebarProps) {
  return (
    <Flex
      position="sticky"
      top="0"
      bottom="0"
      left="0"
      direction="column"
      justify="between"
      height="100vh"
      width="256px"
      minWidth="256px"
      className={styles.Container}
      px="2"
      pt="4"
      pb="2"
    >
      <Flex direction="column" gap="1" justify="start">
        <SidebarLogo />
        {children}
      </Flex>
      <SidebarDropdownMenu user={user} onLogout={logout} />
    </Flex>
  );
}
