import { Avatar, Box, Flex, Text } from "@radix-ui/themes";
import { User } from "../../../types";

type SidebarUserProps = {
  user: User | null;
};

export default function SidebarUser({ user }: SidebarUserProps) {
  const username =
    user?.firstName && user?.lastName
      ? `${user.firstName} ${user.lastName}`
      : user?.email;

  const initials = user
    ? user.firstName && user.lastName
      ? `${user.firstName?.charAt(0)}${user.lastName?.charAt(0)}`
      : user.email.charAt(0)
    : "A";

  return (
    <Flex align="center">
      <Box mr="3">
        <Avatar
          src={user?.profilePictureUrl && user.profilePictureUrl}
          fallback={initials}
          size="2"
        />
      </Box>
      <Box maxWidth="148px">
        <Text as="div" weight="medium" size="3" truncate>
          {username}
        </Text>
      </Box>
    </Flex>
  );
}
