import { CaretUpDown, SignOut } from "@phosphor-icons/react";
import { Button, DropdownMenu } from "@radix-ui/themes";
import SidebarUser from "../SidebarUser";
import { User } from "../../../types";
import style from "./style.module.css";

type SidebarDropdownMenuProps = {
  user: User | null;
  onLogout: () => void;
};

export default function SidebarDropdownMenu({
  user,
  onLogout,
}: SidebarDropdownMenuProps) {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <Button size="3" variant="ghost" color="gray" className={style.Button}>
          <SidebarUser user={user} />
          <CaretUpDown />
        </Button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Content
        variant="soft"
        color="gray"
        style={{ width: "236px" }}
      >
        <DropdownMenu.Item onSelect={onLogout}>
          <SignOut size="16px" /> Logout
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}
