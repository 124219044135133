import { Flex, Heading, useThemeContext } from "@radix-ui/themes";
import logoLight from "./logo-lightmode.png";
import logoDark from "./logo-darkmode.png";
import style from "./style.module.css";

export default function SidebarLogo() {
  const { appearance } = useThemeContext();
  const logoUrl = appearance === "dark" ? logoDark : logoLight;

  return (
    <Flex p="3" align="center">
      <img src={logoUrl} alt="Freqens logo" className={style.Logo} />
      <Heading size="4">Freqens</Heading>
    </Flex>
  );
}
