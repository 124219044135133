import { AlertDialog, Button, Flex } from "@radix-ui/themes";
import { useError } from "./use-error";

const ErrorModal = () => {
  const { error, setError } = useError();

  if (!error) return null;

  const clearError = () => setError(null);

  return (
    <AlertDialog.Root open={!!error} onOpenChange={clearError}>
      <AlertDialog.Content>
        <AlertDialog.Title>Error</AlertDialog.Title>
        <AlertDialog.Description>{error}</AlertDialog.Description>

        <Flex gap="3" mt="4" justify="end">
          <AlertDialog.Action>
            <Button color="gray" onClick={clearError}>
              Got it
            </Button>
          </AlertDialog.Action>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};

export default ErrorModal;
