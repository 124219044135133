import { Theme as RadixTheme } from "@radix-ui/themes";
import { useEffect, useState } from "react";

export default function Theme({ children }: { children: React.ReactNode }) {
  const [mode, setMode] = useState<"light" | "dark">(
    window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light",
  );

  useEffect(() => {
    const eventListener = (e: MediaQueryListEvent) => {
      setMode(e.matches ? "dark" : "light");
    };

    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", eventListener);

    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", eventListener);
    };
  }, [setMode]);

  return (
    <RadixTheme
      accentColor="grass"
      grayColor="sage"
      radius="medium"
      appearance={mode}
    >
      {children}
    </RadixTheme>
  );
}
