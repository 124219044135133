import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
// Import order of css is important
import "./index.css";
import App from "./App.tsx";
import AuthGuard from "./components/AuthGuard.tsx";
import AuthApolloProvider from "./components/ApolloProvider.tsx";
import ErrorPage from "./pages/error.tsx";
import "./services/sentry";
import Theme from "./components/Theme.tsx";
import advancedFormat from "dayjs/plugin/advancedFormat";
import dayjs from "dayjs";
import { ErrorProvider } from "./errors/ErrorProvider.tsx";
import ErrorModal from "./errors/ErrorModal.tsx";

dayjs.extend(advancedFormat);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Navigate to="vendors" /> },
      {
        path: "vendors",
        lazy: () => import("./pages/vendors/index.tsx"),
      },
      {
        path: "vendors/:id",
        lazy: () => import("./pages/vendor/index.tsx"),
      },
      {
        path: "vendors/:vendor_id/products/:id",
        lazy: () => import("./pages/product/index.tsx"),
      },
      {
        path: "subscriptions",
        lazy: () => import("./pages/subscriptions/index.tsx"),
      },
    ],
  },
  {
    path: "/redirect/*",
    lazy: () => import("./pages/webhooks/index.tsx"),
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  // <React.StrictMode>
  <Theme>
    <ErrorProvider>
      <AuthGuard>
        <AuthApolloProvider>
          <RouterProvider router={router} />
        </AuthApolloProvider>
      </AuthGuard>
      <ErrorModal />
    </ErrorProvider>
  </Theme>
  // </React.StrictMode>,
);
