import { Badge, Box, Button } from "@radix-ui/themes";
import style from "./style.module.css";
import { Icon } from "@phosphor-icons/react";
import clsx from "clsx";
import { Link } from "react-router-dom";

type SidebarButtonProps = {
  active?: boolean;
  to: string;
  icon: Icon;
  children: React.ReactNode;
  soon?: boolean;
};

export default function SidebarButton({
  active = false,
  soon = false,
  to,
  icon: ButtonIcon,
  children,
}: SidebarButtonProps) {
  const content = (
    <>
      <ButtonIcon size="18px" />
      {children}
      {soon && (
        <Badge size="1" variant="outline">
          Soon
        </Badge>
      )}
    </>
  );

  return (
    <Button
      size="3"
      style={{
        justifyContent: "flex-start",
      }}
      className={clsx({ [style.Inactive]: !active })}
      variant={active ? "soft" : "ghost"}
      color={active ? "grass" : "gray"}
      disabled={soon}
      asChild
    >
      {soon ? <Box>{content}</Box> : <Link to={to}>{content}</Link>}
    </Button>
  );
}
