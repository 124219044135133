import { Flex, Spinner } from "@radix-ui/themes";
import { createContext, useCallback, useEffect, useState } from "react";
import { User } from "../types";
import { setUser as setSentryUser } from "../services/sentry";

type UserContext = {
  user: User | null;
  login: () => void;
  logout: () => void;
};

export const CurrentUserContext = createContext<UserContext>({
  user: null,
  login: () => {},
  logout: () => {},
});

type CurrentUserProviderProps = {
  user: User | null;
  children: React.ReactNode;
  login: () => void;
  logout: () => void;
};

function CurrentUserProvider({
  user,
  login,
  logout,
  children,
}: CurrentUserProviderProps) {
  return (
    <CurrentUserContext.Provider value={{ user, login, logout }}>
      {children}
    </CurrentUserContext.Provider>
  );
}

function fetchAuthApi(path: string) {
  return fetch(`${import.meta.env.VITE_APP_AUTH_API_URI}${path}`, {
    mode: "cors",
    credentials: "include",
  });
}

type AuthGuardProps = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const [authLoading, setAuthLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  const handleLogin = useCallback(async () => {
    if (authLoading) return;

    setAuthLoading(true);
    const res = await fetchAuthApi("/1/login");

    if (res.status === 401) {
      console.error("Could not login", res.status, await res.text());
      return;
    }

    const { authorizationUrl } = await res.json();

    window.location = authorizationUrl;
  }, [authLoading, setAuthLoading]);

  useEffect(() => {
    (async () => {
      const res = await fetchAuthApi("/1/me");

      if (res.status === 401) {
        setUser(null);
        setAuthLoading(false);
        return;
      }

      const { user, organizationId } = await res.json();

      setUser({
        ...user,
        organizationId,
      });
      setSentryUser(user);
      setAuthLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (!authLoading && !user) {
      handleLogin();
    }
  }, [authLoading, user, handleLogin]);

  const handleLogout = async () => {
    const res = await fetchAuthApi("/1/logout");

    if (res.status === 401) {
      return handleLogin();
    }

    setAuthLoading(true);
    setUser(null);

    const { logoutUrl } = await res.json();
    window.location = logoutUrl;
  };

  if (authLoading || !user) {
    return (
      <Flex height="100vh" align="center" justify="center">
        <Spinner size="3" />
      </Flex>
    );
  }

  return (
    <CurrentUserProvider user={user} login={handleLogin} logout={handleLogout}>
      {children}
    </CurrentUserProvider>
  );
}
